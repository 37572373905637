/*================================
    #RESPONSIVE CSS
====================================*/

/* Tablet Layout: 768px.*/
@media only screen and (min-width: 768px) and (max-width: 991px) {

  /* Container Full */
  .container-full .row {
    display: block;
  }

  .container-full .row > .info {
    max-width: 100%;
  }

  .container-full .row > .info {
    padding: 80px;
  }

  .banner-area.bg-gradient .content .thumb img {
    max-width: 100%;
  }

  .banner-area.top-pad-50p .content.double-items {
  padding-top: 100px;
}

.banner-area.standard .thumb-box {
    display: none;
}

.banner-area.standard .fixed-shape {
    opacity: 0.4;
}

.banner-area.standard .content {
    padding: 0;
}

.banner-area.standard .info {
    padding: 120px 15px;
}

  /* Why Chose Us */
  .chose-us-area .row > .info {
    padding-bottom: 70px;
  }

  .chose-us-area .thumb {
    min-height: 350px;
  }

  .chose-us-area.item-half .container-full .row > .info {
    padding: 80px 80px 75px;
    overflow: hidden;
  }

  .chose-us-area.item-half .info li:first-child,
  .chose-us-area.item-half .info li:nth-child(2) {
    margin-top: 0;
  }

  .chose-us-area.item-half .info li:first-child,
  .chose-us-area.item-half .info li:nth-child(2n) {
    padding: 0;
  }

  .services-details-area .sidebar {
    margin-top: 50px;
  }

  /* Top Services */
  .top-services-area .services-tabs ul.nav.nav-pills li {
    display: block;
    float: none;
  }

  .top-services-area .services-tabs ul.nav.nav-pills li a::after,
  .top-services-area .services-tabs ul.nav.nav-pills li.active a::after {
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff;
    content: "";
    height: 100%;
    width: 100%;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    z-index: -1;
}

.top-services-area .services-tabs ul.nav.nav-pills li a {
    justify-content: center;
    position: relative;
    z-index: 1;
}

.top-services-area .services-tabs {
    background: transparent;
    border-bottom: none;
    margin-bottom: 70px;
    margin-top: 65px;
}

.top-services-area .services-tabs ul.nav.nav-pills {
  margin-left: -15px;
  margin-right: -15px;
}

.top-services-area .services-tabs ul.nav.nav-pills li {
    float: left;
    width: 50%;
    margin: 0;
    padding: 0 15px;
    margin-bottom: 30px;
}

.top-services-area .services-tabs ul.nav.nav-pills li:last-child {
  margin-bottom: 0;
}

.top-services-area .info {
    padding-left: 15px;
}

.top-services-area .thumb {
  margin-bottom: 30px;
}

.top-services-area .thumb::after {
  display: none;
}

  /* Topbar Area */

  .top-bar-area .address-info {
    overflow: hidden;
    border-bottom: 1px solid #e7e7e7;
  }

  .top-bar-area.bg-theme .address-info {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .top-bar-area .bar-btn {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .top-bar-area .address-info .info {
    text-align: center;
  }

  .top-bar-area .address-info ul {
    overflow: hidden;
    display: inline-block;
    margin: auto auto -10px;
    text-align: center;
    float: none;
  }

  .top-bar-area .text-right {
    text-align: center;
  }

  .top-bar-area li .icon,
  .top-bar-area li .info {
    display: block;
  }

  .top-bar-area li .icon i {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .top-bar-area.double-info .address-info li {
    margin: 0;
    padding: 20px;
  }

  .top-bar-area.double-info .address-info li::after,
  .top-bar-area.double-info .address-info li::after {
    display: none;
  }

  /* Breadcrumb */
  .breadcrumb-area .text-right {
    text-align: center;
    margin-top: 15px;
  }

  .breadcrumb-area {
    text-align: center;
  }

  /* Banner */
  .banner-area.responsive-auto-height.text-small .content h1 {
    font-size: 45px;
  }

  /* Top Entry */
  .top-entry-area .item-box .single-item {
    overflow: hidden;
  }

  /* About Area */
  .about-area .tabs-items {
    margin-top: 30px;
  }

  .about-items .emergency-services {
    margin-top: 30px;
  }

  .about-area .about-items .inc-video {
    margin-bottom: 30px;
  }

  .about-area .info {
    padding-right: 15px;
  }

  .about-area .services-area .equal-height:first-child,
  .about-area .services-area .equal-height:nth-child(2) {
    margin-top: 30px;
  }

  /* Health Tips */
  .health-tips-items .info::after {
    left: 50%;
    top: -60px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #ffffff;
    margin-left: -30px;
  }

  .appoinment-box form .col-md-12 {
    float: left;
    width: 100%;
  }


  /* Top Entry */

  .top-entry-area.border-less .single-item {
    margin-bottom: 30px;
  }

  .top-entry-area.border-less .single-item:last-child {
    margin-bottom: 0;
  }

  .top-entry-area.border-less .item {
    border: 1px solid #e7e7e7;
    overflow: hidden;
  }

  .top-entry-area .item-box .single-item:nth-child(2n)::after {
    display: none !important;
  }

  .top-entry-area .item-box .single-item:nth-child(2n) {
    background: transparent;
  }

  .top-entry-area .item-box .single-item:nth-child(2n) .item {
    background: #0cb8b6;
    border: none;
  }

  /* Department */
  .department-tabs .nav-pills {
    margin: 0 -7px;
  }

  .department-tabs .tab-navs li {
    width: 49%;
    margin-bottom: 0;
    padding: 5px 7px;
  }

  .department-tabs .tab-content .thumb {
    margin-top: 25px;
  }

  .department-tabs .tab-content .opening-info {
    padding: 20px 0 0 0;
    background: #ffffff;
    border: none;
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
  }

  /* Doctor Details */
  .doctor-details-area .info {
    margin-top: 30px;
  }

  /* Features */
  .features-area .features-items {
    padding-left: 15px;
    margin-top: 30px;
  }

  .features-area .features-items li {
    display: block;
    text-align: center;
  }

  .features-area .features-items li .info {
    padding-left: 0;
  }

  .features-area .bottom {
    display: block;
    text-align: center;
  }

  .features-area .bottom .content {
    padding-left: 0;
    margin-top: 30px;
  }


  /* Fun Factor */
  .fun-fact-area .item {
    margin-top: 30px;
  }

  .fun-fact-area .item:first-child,
  .fun-fact-area .item:nth-child(2) {
    margin-top: 0;
  }

  /* gallery */
  .gallery-area .gallery-items.col-2 .pf-item,
  .gallery-area .gallery-items.col-3 .pf-item,
  .gallery-area .gallery-items.col-4 .pf-item {
    width: 50%;
  }

  /* Blog */
  .blog-area .sidebar {
    margin-bottom: -10px;
    margin-top: 50px;
    float: left;
    width: 100%;
  }

  .blog-area .blog-items .single-item {
    margin-bottom: 50px;
  }

  .blog-area .blog-items .single-item:last-child {
    margin-bottom: 0;
  }


  /* Footer */

  footer .f-items .item {
    margin-bottom: 50px;
  }

  footer .f-items .item:last-child {
    margin-bottom: 0;
  }

  footer .f-items .item.col-sm-6 {
    margin-bottom: 0;
    margin-top: 50px;
  }

  footer .f-items .item.col-sm-6:first-child,
  footer .f-items .item.col-sm-6:nth-child(2) {
    margin-top: 0;
  }

  footer .footer-bottom {
    text-align: center;
  }

  footer .footer-bottom .text-right {
    text-align: center;
    margin-top: 15px;
  }

  footer.active-first .f-items .item:first-child .f-item {
    padding: 0;
  }

  footer.active-first .f-items .item:first-child .f-item::after {
    display: none;
  }
}


/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {

  /* Heading */
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
    line-height: 1.4;
  }

  h5 {
    font-size: 16px;
    line-height: 1.4;
  }

  h6 {
    font-size: 14px;
    line-height: 1.4;
  }

  .default-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .padding-xl {
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .default-padding.bottom-less {
    padding-bottom: 20px;
    padding-top: 50px;
  }

  .default-padding-bottom {
    padding-bottom: 50px;
  }

  .default-padding-top {
    padding-top: 50px;
  }

  .default-padding.bottom-30 {
    padding-bottom: 0;
    padding-top: 50px;
  }

  .default-padding.bottom-20 {
    padding-bottom: 20px;
    padding-top: 50px;
  }

  .padding-less-mobile {
    padding-top: 0;
  }

  .site-heading {
    margin-bottom: 30px;
  }

  .site-heading.single {
    margin-bottom: 20px;
  }

  .carousel-shadow.default-padding {
    padding-bottom: 35px;
  }

  .bg-fixed {
    background-attachment: inherit;
    background-position: center center;
    background-size: cover;
  }

  .breadcrumb-area {
    padding: 100px 0;
  }

  .breadcrumb-area h1 {
    font-size: 25px;
  }

  .owl-carousel.owl-theme .owl-nav {
    display: none !important;
  }

  /* Topbar */

  .top-bar-area {
    display: none;
  }

.top-bar-area .text-right {
    display: none;
}

.top-bar-area .address-info .info ul {
    display: block;
}

.top-bar-area.inline .info li {
    display: block;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e7e7e7;
    padding-right: 0;
    margin-right: 0;
}

.top-bar-area.inline .info li:last-child {
  border: none;
}

.top-bar-area.inline .info li::after {
  display: none;
}

  nav.navbar .quote-btn {
    display: none;
  }

  .side {
    padding: 50px 50px 50px 35px;
    width: 320px;
  }

  /* Container Full */
  .container-full .row {
    display: block;
  }

  .container-full .row > .info {
    padding: 50px 15px;
    max-width: 600px;
  }


  /* Breadcrumb */

  .breadcrumb-area .text-right {
    text-align: center;
    margin-top: 20px;
  }

  .breadcrumb-area {
    text-align: center;
  }

  .breadcrumb-area h1 {
    padding-top: 0;
    margin: -10px;
  }

  /* Banner Area */

  body, .banner-area, .banner-area div {
    height: auto;
  }

  .banner-area .content {
    overflow: hidden;
    padding: 50px 0;
  }

  .banner-area .transparent-nav .content {
    padding-top: 110px;
  }

  .banner-area .content h1 {
    font-size: 32px;
    line-height: 1.2;
  }

  .banner-area .heading-uppercase .content h1 {
    font-size: 36px;
  }

  .banner-area .text-center p {
    padding: 0;
  }

  .banner-area.heading-exchange .content h1 {
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .banner-area.heading-exchange .content h2,
  .banner-area.heading-exchange .content h3,
  .banner-area.heading-exchange .content h4 {
    margin-bottom: 20px;
  }

  .banner-area.responsive-auto-height,
  .banner-area.responsive-auto-height div {
    height: auto;
  }

  .banner-area .double-items {
    display: block;
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .banner-area.text-small .content h1 {
    font-size: 36px;
    line-height: 1.2;
  }

  .banner-area .appoinment-box {
    margin-top: 30px;
  }

  .banner-area .double-content .container > .row {
    display: block;
  }

  .banner-area.heading-exchange .content h1 {
    font-size: 36px;
  }

  .banner-area .double-content .content {
    padding-top: 30px;
  }

  .banner-area .double-content .thumb {
    padding-right: 15px;
    margin-top: 50px;
  }

  .banner-area.bg-gradient .content .thumb {
  padding-left: 15px;
  margin-top: 30px;
}

.banner-area.bg-gradient .content .thumb img {
  max-width: 100%;
}

.banner-area.top-pad-50p .content.double-items {
  padding-top: 100px;
}

.banner-area.border-shape::after {
  display: none;
}

.banner-area.standard .thumb-box {
    display: none;
}

.banner-area.standard .fixed-shape {
    opacity: 0.4;
}

.banner-area.standard .content {
    padding: 0;
}

.banner-area.standard .info {
    padding: 50px 15px;
}

.banner-area.standard .content h1 {
    font-size: 36px;
}

  .side-bg {
    display: none;
  }

  .appoinment-box {
    padding: 30px;
  }

  .appoinment-box form .col-md-12 {
    float: left;
    width: 100%;
  }

  /* Top Entry */
  .top-entry-area .item {
    padding: 30px;
  }

  .top-entry-area .item-box .single-item {
    overflow: hidden;
  }

  /* Top Entry */

  .top-entry-area.border-less .single-item {
    margin-bottom: 30px;
  }

  .top-entry-area.border-less .single-item:last-child {
    margin-bottom: 0;
  }

  .top-entry-area.border-less .item {
    border: 1px solid #e7e7e7;
    overflow: hidden;
    padding: 30px;
  }

  .top-entry-area .item-box .single-item:nth-child(2n)::after {
    display: none !important;
  }

  .top-entry-area .item-box .single-item:nth-child(2n) {
    background: transparent;
  }

  .top-entry-area .item-box .single-item:nth-child(2n) .item {
    background: #0cb8b6;
    border: none;
  }

  /* About */
  .about-area .about-items .inc-video {
    margin-bottom: 30px;
  }

  .about-area .info {
    padding-right: 15px;
  }

  .about-area .bottom {
    display: block;
    text-align: center;
  }

  .about-area .bottom .content {
    padding-left: 0;
    margin-top: 30px;
  }

  .about-area .tabs-items {
    margin-top: 30px;
  }

  .about-area .services-area .equal-height:first-child,
  .about-area .services-area .equal-height:nth-child(2) {
    margin-top: 30px;
  }

  .video-bg-area {
    padding: 80px 0;
  }

  .tabs-items .nav-pills {
    text-align: center;
  }

  .tabs-items .nav-pills li {
    float: none;
    display: inline-block;
  }

  .tabs-items .nav-pills li.active a::after {
    display: none;
  }

  .tabs-items .nav-pills li a {
    margin-bottom: 10px;
  }

  /* Doctor Details */
  .doctor-details-area .info {
    margin-top: 30px;
  }

  .doctor-details-area .nav-pills {
    text-align: center;
  }

  .doctor-details-area .nav-pills li {
    margin: 5px 10px;
    float: none;
    display: inline-block;
  }

  .doctor-details-area .nav-pills li.active::after {
    display: none;
  }

  .doctor-details-area .nav-pills li a {
    margin: 0;
    padding: 0;
  }

  .doctor-details-area .nav-pills {
    margin-bottom: 30px;
    margin-top: 25px;
    padding-bottom: 20px;
  }

  /* Features */
  .features-area .features-items {
    padding-left: 15px;
    margin-top: 30px;
  }

  .features-area .features-items li {
    display: block;
    text-align: center;
  }

  .features-area .features-items li .info {
    padding-left: 0;
  }

  .features-area .bottom {
    display: block;
    text-align: center;
  }

  .features-area .bottom .content {
    padding-left: 0;
    margin-top: 30px;
  }

  /* Services */
  .solid-services-area .item {
    padding: 50px 30px;
  }

  .services-area .services-carousel.owl-carousel .owl-nav {
    display: none;
  }

  .services-details-area .sidebar {
    margin-top: 50px;
  }



/* Top Services */
  .top-services-area .services-tabs ul.nav.nav-pills li {
    display: block;
    float: none;
  }

  .top-services-area .services-tabs ul.nav.nav-pills li a::after,
  .top-services-area .services-tabs ul.nav.nav-pills li.active a::after {
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff;
    content: "";
    height: 100%;
    width: 100%;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    z-index: -1;
}

.top-services-area .services-tabs ul.nav.nav-pills li a {
    justify-content: center;
    position: relative;
    z-index: 1;
}

.top-services-area .services-tabs {
    background: transparent;
    border-bottom: none;
    margin-bottom: 70px;
    margin-top: 65px;
}

.top-services-area .services-tabs ul.nav.nav-pills li {
  margin-bottom: 30px;
}

.top-services-area .services-tabs ul.nav.nav-pills li:last-child {
  margin-bottom: 0;
}

.top-services-area .info {
    padding-left: 15px;
}

.top-services-area .thumb {
  margin-bottom: 30px;
}

.top-services-area .thumb::after {
  display: none;
}

  /* Chose Us */
  .chose-us-area .thumb {
    min-height: 300px;
  }

  .chose-us-area.item-half .container-full .row > .info {
    padding: 50px 15px 40px;
    overflow: hidden;
  }

  .chose-us-area.item-half .info li {
    float: none;
    width: 100%;
  }

  .chose-us-area.item-half .info li:first-child,
  .chose-us-area.item-half .info li:nth-child(2n) {
    padding: 0;
  }

  .chose-us-area.item-half .info li:first-child,
  .chose-us-area.item-half .info li:nth-child(2) {
    margin-top: 30px;
  }

  .chose-us-area.item-half .info li:first-child {
    margin-top: 0;
  }

  .faq-area {
    margin-top: 30px;
  }

  .services-area.inc-icon.bottom-less {
    padding-bottom: 20px;
  }

  .about-area .emergency-services {
    margin-top: 30px;
    margin-bottom: -10px;
  }

  /* Health Tips */
  .health-tips-items .info::after {
    left: 50%;
    top: -60px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 30px solid #ffffff;
    margin-left: -30px;
  }

  .doctor-tips-area .tips-carousel.owl-carousel .owl-nav {
    display: none;
  }

  /* Department */
  .department-tabs .tab-content .opening-info h4::after {
    left: 50%;
    margin-left: -25px;
    width: 40px;
  }

  .department-tabs .tab-content .opening-info h4::before {
    left: 50%;
    margin-left: 20px;
    width: 5px;
  }

  .department-tabs .tab-content .thumb {
    margin-top: 15px;
  }

  .department-tabs .tab-content .opening-info {
    padding: 20px 0 0 0;
    background: #ffffff;
    border: none;
    border-top: 1px solid #e7e7e7;
    margin-top: 20px;
  }

  .department-tabs {
    text-align: center;
  }

  .department-tabs .tab-content .opening-info li {
    text-align: left;
  }

  /* Fun Factor */
  .fun-fact-area .item {
    margin-bottom: 30px;
  }

  .fun-fact-area .item:last-child {
    margin-bottom: 0;
  }

  /* Testimonials */
  .testimonial-items {
    margin-top: 10px;
  }

  .testimonial-items .provider {
    display: block;
    text-align: center;
  }

  .testimonial-items .provider .thumb {
    margin-right: 0;
  }

  .testimonial-items .provider img {
    margin: auto auto 15px;
  }


  /* gallery */
  .gallery-area {
    padding-bottom: 35px;
  }

  .mix-item-menu {
    border: none;
    padding: 0;
  }

  .mix-item-menu button {
    padding: 8px 25px;
    border: 1px solid #e7e7e7;
    margin: 5px;
    background: #ffffff;
  }

  .mix-item-menu button.active::after {
    top: 12px;
  }

  .gallery-area .gallery-items.col-2 .pf-item,
  .gallery-area .gallery-items.col-3 .pf-item,
  .gallery-area .gallery-items.col-4 .pf-item {
    width: 100%;
  }

  /* Blog */
  .blog-area .blog-items .single-item {
    margin-bottom: 50px;
  }

  .blog-area .blog-items .single-item:last-child {
    margin-bottom: 0;
  }

  .blog-area .sidebar {
    margin-top: 50px;
    float: left;
    width: 100%;
  }

  .blog-area.left-sidebar .blog-content {
    float: none;
  }

  .author-bio .avatar {
    display: block;
    margin-bottom: 30px;
    vertical-align: top;
    width: auto;
  }

  .author-bio .content {
    display: block;
    padding: 0;
  }

  .blog-area .author-bio {
    text-align: center;
  }

  .blog-area .author-bio img {
    border-radius: 50%;
    height: 300px;
    width: 300px;
    margin: auto;
  }

  .blog-area.single .blog-items .item .contact-comments .col-md-6 {
    float: none;
    padding: 0 15px;
  }

  .blog-area.single .content-box .meta .date {
    float: left;
    width: 100%;
    margin-bottom: 15px;
  }

  .comments-list .commen-item.reply {
    padding-left: 0;
  }

  .comments-info a {
    margin-left: 0;
  }

  .comments-area .commen-item .comments-info p {
    display: block;
    margin-bottom: 15px;
  }


  /* Google Maps */
  .google-maps iframe {
    height: 300px;
  }


  /* Error Page */
  .error-box .search form {
    width: 300px;
  }

  .error-page-area .error-box h1 {
    font-size: 120px;
  }

  /* Footer */

  footer .f-items .item {
    margin-bottom: 50px;
  }

  footer .f-items .item:last-child {
    margin-bottom: 0;
  }

  footer .footer-bottom {
    text-align: center;
  }

  footer .footer-bottom .text-right {
    text-align: center;
    margin-top: 15px;
  }

  footer.active-first .f-items .item:first-child .f-item {
    padding: 0;
  }

  footer.active-first .f-items .item:first-child .f-item::after {
    display: none;
  }
}

/* Max Wide Mobile Layout: 600px. */
@media only screen and (min-width: 600px) and (max-width: 767px) {


/* Topbar Area */

.top-bar-area .address-info .info ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-bar-area.inline .info li {
  border: none;
  padding: 15px 5px;
}

.top-bar-area.inline .info li::after {
  display: inline-block;
}

.top-bar-area.inline .info li:last-child::after {
  display: none;
}


  /* gallery */
  .gallery-area .gallery-items.col-2 .pf-item,
  .gallery-area .gallery-items.col-3 .pf-item,
  .gallery-area .gallery-items.col-4 .pf-item {
    width: 50%;
  }
}

/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {


  .side {
    padding: 50px;
    width: 400px;
  }

  /* Testimonials */

  .testimonial-items .provider {
    display: flex;
    text-align: left;
  }

  .testimonial-items .provider .thumb {
    margin-right: 15px;
  }

  .testimonial-items .provider img {
    margin: 0;
  }
}


@media only screen and (max-width: 400px) {

.top-services-area .services-tabs ul.nav.nav-pills li a {
    display: block;
    text-align: center;
}

.top-services-area .services-tabs ul.nav.nav-pills li a i {
  display: block;
  margin-right: 0;
  margin-bottom: 15px;
}

}
